.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.clickable:hover{
  cursor: pointer;
}

.wordCount{
	font-size: 10px;
	color:darkgray;
}

.pt-20{
padding-top: 20px;
}

.ml-5{
margin-left: 5px;
}

.settingsForm{
  position:absolute;
  width: 100%;
  padding:25px;
  background-color: #444544EE;
  border-radius: 10px;
  z-index: 99;
  max-height: 245px;
  height: 100%;
}

.chart-subTitle{
  font-size: 18px;
  color:darkgrey;
}

.mb-50{
  margin-bottom: 50px;
}

.react-datepicker-wrapper{
  width: 55%;
}

.react-datepicker__input-container input{
  padding: 5px;
  border-radius: 5px;
  font-size: 10px;
  width: 100%;
  margin-top:5px;
  margin-bottom:5px;
 
}

.recharts-tooltip-wrapper .recharts-tooltip-wrapper-right .recharts-tooltip-wrapper-top{
  z-index: 95;
}

/* User management */


.MuiTableCell-root{
  font-size:1.5rem;
}

.quill::placeholder{
  color:red;

}

.quill::-webkit-input-placeholder{
  color: red;
}

.color-red{
  color:red;
}

@media only screen and (min-width:425px){
.react-datepicker {
  font-size: 1em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1.5em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}
}
@media only screen and (max-width:425px){
  .button{
    width:100%;
  }
  .publish-btn{
    margin-top:10px;
  }
  .continue-btn{
    margin-top:10px;
  }
  .submit-btn{
    margin-top: 15px;
  }
}
.publish-btn{
  background-color:#00ab6b;
  border-color: #00ab6b;
  color:white;
}

.datepicker_border{
  border: 1px solid black;
}

.preview_card{
  color: #00ab6b;
  
}

.sidebar .logo a.logo-mini{
  float: none;
  width: 100%;
}

.sidebar .logo a.logo-mini img{
  width:160px;
}


.sidebar .logo .logo-img{
width:160px;
}


.xotkariIframePluginFrame iframe {
width: 100%;
height: 300px;
}

.react-datepicker-popper {
z-index: 99;
}

.react-datepicker-wrapper {
width: 100%;
}
.react-datepicker__input-container input {
font-size: 14px;

}

.react-datepicker__input-container input {
padding: 9px;
border: lightgrey 1px solid;
margin-top:0px;
}

.primaryButton{
  color: #fff;
  background-color: rgb(1, 87, 155);
  border-color: rgb(1, 87, 155);
}


.primaryButton:focus{
color: #fff;
background-color: rgb(1, 87, 155);
border-color: rgb(1, 87, 155);  
}

.dangerButton{
  color: #fff;
  background-color: #ac2e3b;
  border-color: #ac2e3b;
}

.dangerButton:focus{
  outline: none;
}
  
.customDateChoose{
cursor: grab;
}

.scheduleBtn:hover{
color: #fff;
background-color: rgb(61 140 202);
border-color: rgb(61 140 202)  
}

.primaryBtn{
color: #fff;
background-color: rgb(1, 87, 155);
border-color: rgb(1, 87, 155);
}

.primaryBtn:hover{
  color: #fff;
  background-color: rgb(5, 114, 197);
  border-color: rgb(1, 87, 155);
}

.successBtn{
color: #fff;
background-color: #049F0C;
border-color: #049F0C;
}

.successBtn:hover{
color:#049F0C;
background-color: white ;
border-color: #049F0C;
border:2px solid #049F0C;
}

.infoBtn{
color: #fff;
background-color: #42d0ed;
border-color: #42d0ed;
}

.infoBtn:hover{
color:#42d0ed;
background-color: white ;
border-color: #42d0ed;
border:2px solid #42d0ed;
}

.imagePlaceholder:hover{
  cursor:grab;
}

.mr--15{
margin-right: 15px;
}

.mr--40{
margin-right: 40px;
}

.dropdown:not(.dropdown-open)>.dropdown-menu {
display: block;
}

.errorMessage{
color: rgb(233, 23, 23);
font-size: 12px;
}

.factIcon{
font-size:20px;
}

.previewImageWrapper{
  position: relative;
  color:lightgrey;
}

.previewImageWrapper:hover{
  cursor: pointer;
}

.previewImageWrapper:hover .previewImageOverlay{
  display:block;
}

.previewImageOverlay{
  position: absolute;
  color:gold,
  /* height: 100%; */
  /* width: 30%; */
  /* background-color: rgba(0, 0, 0, 0.685); */
  /* background-image: url("../img/star.png"); */
  /* background-size: 50%; */
  /* background-repeat: no-repeat; */
  /* background-position: center; */
}

.previewImage{
  display: block;
  width: 100%;
}

.selectedImage {
  color: gold;
  border: 5px solid #8fb650 ;
}

.selectedImage .previewImageOverlay{
  display: block;
}

.MuiInputBase-input{
  padding: 15px 0px;
  font-size: 13px;
}

.tagManageWrapper{
  min-height:91vh;
  padding: 50px 10px 10px 10px;
}

.tagWrapper{
  display:flex;
  justify-content:space-around;
  flex-wrap:wrap;
  flex-direction:row;   
  align-content: flex-start;
  z-index: 10;
}

.tagBlock {
  padding:15px 20px;
  background-color:gainsboro;
  margin:10px;
  transform: scale(1.0);
  /* transition: transform 0.2s, background-color 0.3s; */
}

.tagStatusBadge{
  padding:5px 10px;
  border-radius: 5px;
}

.tagStatHeader{
  margin-bottom: 6px;
}

.phr-10{
  padding: 0px 10px;
}

.btn-sm{
  padding:2px 10px;
  font-size: 13px;
  border-radius: 5px;
}

.waitingTagBlock { background-color:gainsboro; color: black;}
.validTagBlock { background: #8fb650; color: white;}
.invalidTagBlock { background: rgb(245, 134, 115); color: white;}

.WaitingText{color:black;font-weight: bold;}
.ValidText{color:#8fb650;font-weight: bold}
.InvalidText{color:red;font-weight: bold}

.tagOptions{
  left:0;
  bottom:-20px;
  z-index: 200;
  opacity: 0;
  position:absolute;
  margin-left: 10px;
  color:white;
  border-radius: 5px;
  background:#4299e1;
  transition: opacity 0.3s, bottom 0.3s;
}

.hide{
  display: none;
}

.tagBlockWrapper:hover .tagOptions {
  opacity: 1;
  bottom: -34px;
}

.tagOptions ul{
  display: flex;
  margin-bottom: 0px;
  list-style-type: none;
  flex-direction: row;
}

.tagOptions ul li{
  padding: 10px;
}

.tagOptions ul li.validBtn:hover{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: #8fb650 ;
}

.tagOptions ul li.invalidBtn:hover{
  background-color: rgb(245, 134, 115);
}

.tagOptions ul li.settingBtn:hover{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #42d0ed ;
}

.tagOptions ul li:hover{
  cursor: pointer;
}


.tagBlock:hover{
  cursor: pointer;
}

.selectedTag{
  border:1px solid black;
}

.tagActions{
  display: flex;
  margin-top: 30px;
  align-items: center;
  justify-content: space-between;
}

.tagActions button{
  margin-right: 20px;
  padding: 15px 40px;
  background-color: #4299e1;
  color: white;
  transform: scale(1.0);
  transition: transform 0.2s;
}

.tagActions .selectedLabel{
  flex: auto;
  align-self: center;
}

.tagActions .publishBtn:hover{
  transform: scale(1.05);
}

.tagActions .cancelBtn{
  color: white;
  background-color:rgb(245, 134, 115);
}

.tagActions .cancelBtn:hover{
  transform: scale(1.05);
}


.tagArticlesWrapper .header{
  margin-top: 20px;
  margin-bottom: 10px;
}

.tagArticlesWrapper .content{
  min-height: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tagOverflowScroll{
  overflow-y: scroll;
  height: 500px;
}

.tagArticlesWrapper .content .articleCard {
  width: 48%;
  margin: 6px 0px;
  border: 1px solid lightgray;
  display: flex;
  max-height: 250px;
  overflow: hidden;
}


.tagArticlesWrapper .content .articleCard .imgBlock {
  width: 40%;
  height: 100%;
  flex: 45%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.tagArticlesWrapper .content .articleCard .detailBlock{
  flex: 65%;
  padding:13px 12px;
}

.tagArticlesWrapper .content .articleCard .textContent{
  height: 200px;
  margin-bottom: 10px;
  overflow: hidden;
}

.tagArticlesWrapper .content .articleCard .detailBlock .title{
  font-weight: bold;
}

.tagArticlesWrapper .content .articleCard .detailBlock .titleLink{
  color:black;
  font-weight: bold;
}

.tagArticlesWrapper .content .articleCard .detailBlock .titleLink:hover{
  color: #00ab6b;
}

.tagArticlesWrapper .content .articleCard .detailBlock .metadata{
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width:426px){
  .tagArticlesWrapper .content .articleCard {
    width: 100%;
  }
}

