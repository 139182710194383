a {
    color: #9c27b0;
    text-decoration: none;
}

.gradient {
    background: linear-gradient(90deg, #d53369 0%, #daae51 100%);
}

.bg-fb-blue{
    background-color : #3b5998
}

.bg-google-red{
    background-color : #DB4437
}

.text-single-line{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}



.formContainer {
    width: 100%;
  }

.formFrame {
    margin: 0 auto;
}