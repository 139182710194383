.heading{
  font-size: 14px;
}

.contentWrapper{
  padding: 15px 0px;
}

.centeredContent{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.listWrapper{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
}

.similarTagBlock{
  position: relative;
  flex-wrap: wrap;
  padding:10px 15px;
  margin:5px 10px;
}

.duplicateBlock{
  z-index: 10;
  width: max-content;
  padding: 10px 10px;
  background: antiquewhite;
  position: absolute;
  display: none;
}

.icon{
  margin-left: 5px;
}

.similarTagBlock:hover .duplicateBlock{
  display: block;
}

.similarTagBlock:hover{
  cursor: pointer;
}

.actionWrapper{
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
}

.tagName{
  padding-left: 10px;
}

.dangerText{
  color:tomato
}

.validTag {
  color: green;
}

.invalidTag {
  color: red;
}
